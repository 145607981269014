export enum NotificationMessage {
  CreateRequestAdditionalProduct = 'create_request_additional_product',
  UpdateRequestAdditionalProduct = 'update_request_additional_product',
  CreateRequestJsonIncreaseLimit = 'create_request_json_increase_limit',
  UpdateRequestJsonIncreaseLimit = 'update_request_json_increase_limit',
  CreateRequestInitialProductList = 'create_request_initial_product_list',
  UpdateRequestInitialProductList = 'update_request_initial_product_list',
  CreateRequestSustainableAlternative = 'create_request_sustainable_alternative',
  CreateRequestUpdatePcfValues = 'create_request_update_pcf_values',
}
