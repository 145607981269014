// eslint-disable camelcase

import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { ICompany } from '../../shared/interfaces/ICompany';
import { IUser } from '../../shared/interfaces/IUser';
import { UserRole } from '../../shared/types/User';

type IUserDetails = Pick<IUser, 'id' | 'first_name' | 'last_name' | 'email'> & {
  company: Pick<
    ICompany,
    'id' | 'company_id' | 'company_name' | 'edc_configuration_status' | 'license'
  >;
  language: string;
};

type AddUserMutationProps = {
  email: string;
  first_name: string;
  last_name: string;
  company_id: number;
  language: string;
  role: UserRole;
};
type AddUserResponse = { id: number };

type GetInvitationLinkMutationProps = {
  userId: number;
};
export type GetInvitationLinkResponse = {
  inviteLink: string;
};

type AssignAdminRoleMutationProps = {
  userId: number;
};

const amplifyApiService = new AmplifyApiService();

export const useGetUser = (): UseQueryResult<IUserDetails> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'user/me',
    init: {},
  };

  const query = useQuery<IUserDetails, Error>({
    queryKey: ['user', 'me'],
    queryFn: async (): Promise<IUserDetails> => {
      const response: IUserDetails = await amplifyApiService.get<IUserDetails>({
        config: amplifyApiConfig,
      });

      return response;
    },
    enabled: false,
  });

  return query;
};

export const useAddUser = (
  options: UseMutationOptions<AddUserResponse, Error, AddUserMutationProps> = {},
): UseMutationResult<AddUserResponse, Error, AddUserMutationProps> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'user',
    init: {},
  };

  const mutation = useMutation<AddUserResponse, Error, AddUserMutationProps>({
    mutationFn: async (body: AddUserMutationProps) => {
      return await amplifyApiService.post<AddUserMutationProps, AddUserResponse>({
        config: amplifyApiConfig,
        body,
      });
    },
    ...options,
  });

  return mutation;
};

export const useListUsers = (
  options: UseQueryOptions<IUser[], Error> = {},
): UseQueryResult<IUser[]> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'user',
    init: {},
  };

  const query = useQuery<IUser[], Error>({
    queryKey: ['list-company-users'],
    queryFn: async (): Promise<IUser[]> => {
      const response = await amplifyApiService.get<{ users: IUser[] }>({
        config: amplifyApiConfig,
      });

      return response.users;
    },
    ...options,
  });

  return query;
};

export const useGetInvitationLink = (
  options: UseMutationOptions<
    GetInvitationLinkResponse,
    Error,
    GetInvitationLinkMutationProps
  > = {},
): UseMutationResult<GetInvitationLinkResponse, Error, GetInvitationLinkMutationProps> => {
  const mutation = useMutation<GetInvitationLinkResponse, Error, GetInvitationLinkMutationProps>({
    mutationFn: async ({ userId }: GetInvitationLinkMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `user/${userId}`,
        init: {
          queryStringParameters: {
            action: 'get-invite-link',
          },
        },
      };
      const response = await amplifyApiService.put<undefined, GetInvitationLinkResponse>({
        config: amplifyApiConfig,
        body: undefined,
      });

      return response;
    },
    ...options,
  });

  return mutation;
};

export const useAssignAdminRole = (
  options: UseMutationOptions<void, Error, AssignAdminRoleMutationProps> = {},
): UseMutationResult<void, Error, AssignAdminRoleMutationProps> => {
  const mutation = useMutation<void, Error, AssignAdminRoleMutationProps>({
    mutationFn: async ({ userId }: AssignAdminRoleMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `user/${userId}`,
        init: {},
      };
      return await amplifyApiService.put<{ role: UserRole }, void>({
        config: amplifyApiConfig,
        body: { role: 'company-admin' },
      });
    },
    ...options,
  });

  return mutation;
};
