import { Avatar, AvatarProps } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { OverlayPanel } from 'primereact/overlaypanel';
import { classNames } from 'primereact/utils';
import { MouseEvent, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../../hooks/auth/useAuth';
import useDialogContext from '../../../../../hooks/dialog/useDialogContext';
import { EDCConfigurationStatus } from '../../../../../shared/interfaces/ICompany';
import ReportBug from './report-bug/ReportBug';
import styles from './UserButton.module.css';

const UserButton = () => {
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const { user, signOut } = useAuth();
  const { openDialog } = useDialogContext();

  const userInital = useMemo(() => {
    if (user?.givenName && user?.familyName) {
      return `${user.givenName?.charAt(0) ?? ''}${user.familyName?.charAt(0) ?? ''}`;
    }
    return null;
  }, [user]);

  const onButtonClick = (event: MouseEvent<HTMLAnchorElement>) => {
    overlayPanelRef.current?.toggle(event);
  };

  const onAboutClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    overlayPanelRef.current?.toggle(event);

    openDialog('about-dialog', {
      showCatenaXConfigration:
        user?.company?.edcConfigurationStatus === EDCConfigurationStatus.COMPLETED,
      license: user?.company?.license,
    });
  };

  const onUserManagementClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    overlayPanelRef.current?.toggle(event);
    navigate('/user-management');
  };

  const renderAvatar = (props: AvatarProps) => {
    return userInital ? (
      <Avatar label={userInital} shape='circle' {...props} />
    ) : (
      <Avatar icon='pi pi-user' shape='circle' {...props} />
    );
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <a className={classNames('rounded-circle topbar-action')} onClick={onButtonClick}>
        <span className='icon-overlay'>
          {renderAvatar({
            className: classNames(styles['user-avatar']),
            pt: { label: { className: 'text-sm' } },
          })}
        </span>
      </a>

      <OverlayPanel
        ref={overlayPanelRef}
        className='shadow-2 mt-2'
        pt={{
          content: { className: 'p-0' },
        }}
      >
        <div className='flex flex-column'>
          <div className='flex px-2 pt-2 align-items-center gap-3'>
            <div className='flex justify-content-center align-items-center'>
              {renderAvatar({
                className: classNames('rounded-circle', styles['user-avatar']),
                size: 'large',
                pt: { label: { className: 'text-lg font-semibold' } },
              })}
            </div>

            <div className='flex flex-column gap-1'>
              {(user?.givenName || user?.familyName) && (
                <span className='font-semibold text-black-alpha-90'>
                  {user?.givenName ?? ''} {user?.familyName ?? ''}
                </span>
              )}
              <span className='font-normal text-sm'>{user?.email}</span>
            </div>
          </div>

          <Divider
            type='solid'
            pt={{ root: { className: 'mt-2 mb-0 p-0' }, content: { className: 'p-0 border-none' } }}
          />

          <ReportBug className={styles['list-action-button']} user={user} />

          {user?.role === 'company-admin' && (
            <Button
              text
              className={classNames('pl-4 border-noround', styles['list-action-button'])}
              onClick={onUserManagementClick}
            >
              <i className='cb cb-icon-info user-icon' />
              <span className='pl-3'> {t('userButton.userManagement')}</span>
            </Button>
          )}

          <Button
            text
            className={classNames('pl-4 border-noround', styles['list-action-button'])}
            onClick={onAboutClick}
          >
            <i className='cb cb-icon-info user-icon' />
            <span className='pl-3'> {t('userButton.about')}</span>
          </Button>

          <Divider
            type='solid'
            pt={{ root: { className: 'mt-0 mb-0 p-0' }, content: { className: 'p-0 border-none' } }}
          />

          <Button
            className={classNames('pl-4 border-noround', styles['list-action-button'])}
            text
            onClick={signOut}
          >
            <i className='cb cb-icon-logout user-icon' />
            <span className='pl-3'> {t('userButton.signOut')}</span>
          </Button>
        </div>
      </OverlayPanel>
    </>
  );
};

export default UserButton;
