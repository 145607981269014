import { ComponentProps, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { parseProductIdFromPcfJson } from '../../../../../shared/helpers/parse-product-id-from-pcf-json';
import { IProductDetails } from '../../../interfaces/ProductDetails.interface';
import InfoCard from '../../info-card/InfoCard';
import InfoCardRow from '../../info-card-row/InfoCardRow';
import InplaceEdit from '../../inplace-edit/InplaceEdit';

interface GeneralDetailsSectionProps extends ComponentProps<'div'> {
  productDetails?: IProductDetails;
  isLoading?: boolean;
}

const GeneralDetailsSection = (props: GeneralDetailsSectionProps): JSX.Element => {
  const { productDetails, isLoading, ...rest } = props;

  const { t } = useTranslation();

  const secondaryProductId = productDetails?.own_product_id ?? '';

  return (
    <InfoCard isLoading={isLoading} {...rest}>
      <h5>{t('productDetailsPage.sections.generalDetailsSection.generalDetails')}</h5>

      <InfoCardRow
        label={t('productDetailsPage.sections.generalDetailsSection.yourProductId.label')}
      >
        <InplaceEdit
          fieldId='ownProductId'
          value={secondaryProductId}
          undefinedValue={t(
            'productDetailsPage.sections.generalDetailsSection.yourProductId.undefinedValue',
          )}
          textClassname='text-md'
        />
      </InfoCardRow>

      <InfoCardRow label={t('productDetailsPage.sections.generalDetailsSection.supplierProductId')}>
        {parseProductIdFromPcfJson(productDetails?.productIds) || 'N/A'}
      </InfoCardRow>

      <InfoCardRow label={t('productDetailsPage.sections.generalDetailsSection.supplier')}>
        <span>{productDetails?.companyName}</span>
      </InfoCardRow>

      <InfoCardRow label={t('productDetailsPage.sections.generalDetailsSection.geography')}>
        <span>{productDetails?.pcf.geographyRegionOrSubregion}</span>
      </InfoCardRow>
    </InfoCard>
  );
};

export default memo(GeneralDetailsSection);
